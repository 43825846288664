import React , { useState , useRef , useEffect } from 'react'
import { useParams } from 'react-router-dom';

import TextDisplay from '../../../../component/Typography/TextDisplay'

import Card from '../../../../component/Card/Card'
import TextInputs from '../../../../component/FormInput'
import { ShadePlaceHolder } from '../../../../styles';

import { HiPaperAirplane } from "react-icons/hi2";

import { EstimaticsImg } from '../../../../contants'

import { getDate } from '../../../../contants';
import { APIServices } from '../../../../hooks/fetchAPI';

import CircularProgress from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';


const MessageTab = () => {

  const { id } = useParams();

  const [ message, setMessage ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ messageList, setMessageList ] = useState([]);
  const inputRef = useRef(null);

  const [ notesList , setNotelist ] = useState([]);

  const fetchNotes = async (data) => {
    const res = await APIServices.get({ url: '/note/list/filter?page=1&limit=1000000&workfile=' + id})
    // setMessageList(res.data)

    let dataResult = res?.data?.data?.data.filter(item => item.typeOfNote === data);
    setNotelist(dataResult);

  }


  const fetchMessageData = async () => {
    const res = await APIServices.get({ url: '/workfile/list/' + id + "/message"})
    setMessageList(res.data)
  }

  const submitMessage = async () => {
    setLoading(true);
    let data = {
      content: message,
    }

    const res = await APIServices.post({ url: '/workfile/add/' + id + "/message" , payload: data})
    
    if (res && !res.error && res.data && res.data.data) {
      setLoading(false);
      fetchMessageData()
      setMessage(null)
      inputRef.current.value = '';

    }
  }

  useEffect(() => {
    fetchMessageData();
    fetchNotes('Internal');
  }, []);

  const [value, setValue] = React.useState('0');

  const handleChange = (event, newValue) => {
 
    setValue(newValue);
    switch( newValue ) {
      case '3' : 
          fetchNotes('Customer');
      break;
      case '2' : 
          fetchNotes('Shop'); 
      break;
      case '1' : 
          fetchNotes('Internal'); 
      break;
      default :
          fetchNotes('Message');
    }

  };

  function CustomTabButton({ label, onClick , position }) {
    return (
      <Button onClick={onClick} variant="contained" color={ parseInt( position ) === parseInt( value ) ? "primary" : "inherit"}>
        <span className='px-2'>{label}</span>
        <div className='absolute right-1 bottom-3 '>
          <span className='text-white py-[2px] px-[3px] text-[8px] bg-red-800 border-[2px] rounded'>2</span>
        </div>
      </Button>
    );
  }

  return (
    <div>
        <Card props={'bg-white flex flex-col gap-5'}>

          <div className='w-full flex flex-row items-center gap-2'>

          <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                {/* <Tab label="Message" value="0" />
                <Tab label="Internal" value="1" />
                <Tab label="Shop" value="2" />
                <Tab label="Customer" value="3" /> */}

                <Tab label={ <CustomTabButton label="Message" onClick={() => setValue(0)} position = {0} /> } />
                <Tab label={ <CustomTabButton label="Internal" onClick={() => setValue(1)} position = {1}  /> } />
                <Tab label={ <CustomTabButton label="Shop" onClick={() => setValue(2)} position = {2}  /> } />
                <Tab label={ <CustomTabButton label="Customer" onClick={() => setValue(3)} position = {3}  /> } />

              </TabList>
            </Box>

            <TabPanel value="1">
              
              <div className='w-full flex flex-row gap-4'>
                { notesList?.map ( item => (
                  <Card props={'bg-white flex flex-col gap-5 w-[300px] h-[250px]'}>
                     <span className='text-mainText' style={{ fontSize:14 }}>{getDate(item?.createdAt)}</span>
                     <span className="font-bold" style={{ fontSize:18}}>{item?.title}</span>
                     <span className="font-semibold" style={{ fontSize:16}}>{item?.description}</span>
                     <span className="font-small" style={{ fontSize:14}}>Internal Notes</span>
                  </Card>
                ))} 
              </div>

            </TabPanel>

            <TabPanel value="2">
              <div className='w-full flex flex-row gap-4'>
                  { notesList?.map ( item => (
                    <Card props={'bg-white flex flex-col gap-5 w-[300px] h-[250px]'}>
                      <span className='text-mainText' style={{ fontSize:14 }}>{getDate(item?.createdAt)}</span>
                      <span className="font-bold" style={{ fontSize:18}}>{item?.title}</span>
                      <span className="font-semibold" style={{ fontSize:16}}>{item?.description}</span>
                      <span className="font-small" style={{ fontSize:14}}>Shop Notes</span>
                    </Card>
                  ))} 
                </div>
            </TabPanel>

            <TabPanel value="3">
              <div className='w-full flex flex-row gap-4'>
                  { notesList?.map ( item => (
                    <Card props={'bg-white flex flex-col gap-5 w-[300px] h-[250px]'}>
                      <span className='text-mainText' style={{ fontSize:14 }}>{getDate(item?.createdAt)}</span>
                      <span className="font-bold" style={{ fontSize:18}}>{item?.title}</span>
                      <span className="font-semibold" style={{ fontSize:16}}>{item?.description}</span>
                      <span className="font-small" style={{ fontSize:14}}>Customer Notes</span>
                    </Card>
                  ))} 
                </div>
            </TabPanel>

            <TabPanel value="0">
              
              { messageList?.data?.map ( item => (
                <div className='w-full flex flex-col justify-items-end mb-2'>

                        <div className='w-full flex flex-row-reverse items-center gap-2'>
                          <img src={EstimaticsImg.CHATUSERSICON} alt='icon'/>
            
                          <div className='flex flex-col'>
                            <div className='bg-[#DADADA] px-5 py-5 rounded-bl-xl rounded-tl-xl rounded-br-3xl flex flex-col'>
                              <TextDisplay>
                                <span style={{ fontSize:18}}>{ item?.content }</span>
                              </TextDisplay>
                            </div>
                          </div>
            
                        </div>
            
                        <div className='flex flex-row-reverse relative right-16 ml-1.5 mt-5'>
                          <TextDisplay>
                            <span style={{ fontSize:16}}>sent { getDate( item?.timestamp ) }</span>
                          </TextDisplay>
                        </div>
            
                </div>
              ))}
 
              <div className='w-full flex flex-row items-center justify-between gap-3'>

                <input ref={inputRef} onChange={ (e) => [ setMessage( e.target.value ) ]  }  placeholder={"Type here"} class={`w-full focus:outline-none focus:border-green-700 rounded-lg border border-[${ShadePlaceHolder}] py-3 px-4 my-2 `} type={"text"} />
          
                <div onClick={ ()=> submitMessage() } className='bg-secondaryText py-3 px-4 my-3 cursor-pointer hover:bg-sky-700 rounded-lg h-[45px]'>
                  <span style={{ fontSize:23}} className='text-white flex items-center justify-center'>
                    { loading ? <CircularProgress size={22} color="inherit"/> :  <HiPaperAirplane/> }
                  </span>
                </div>

              </div>

            </TabPanel>

          </TabContext>
        </Box>

            {/* <div className='flex items-center justify-center py-2 px-5 bg-sky-500 rounded-md text-white cursor-pointer hover:bg-sky-800 hover:transition duration-300'>
              <TextDisplay><span style={{ fontSize:14}} className='text-white'>Internal</span></TextDisplay>
            </div> */}

            {/* <div className='flex items-center justify-center py-2 px-5 bg-gray-200 rounded-md text-white cursor-pointer hover:bg-gray-400 hover:transition duration-300'>
              <TextDisplay><span style={{ fontSize:14}} className='text-black'>Customer</span></TextDisplay>
            </div>

            <div className='flex items-center justify-center py-2 px-5 bg-gray-200 rounded-md text-white cursor-pointer hover:bg-gray-400 hover:transition duration-300'>
              <TextDisplay><span style={{ fontSize:14}} className='text-black'>Shop</span></TextDisplay>
            </div> */}

          </div>

          {/* <div className='w-full flex flex-col  justify-center mb-2'>

            <div className='w-full flex flex-row items-center gap-2'>
              <img src={EstimaticsImg.CHATUSERSICON} alt='icon'/>

              <div className='flex flex-col'>
                <div className='bg-[#B3E1FE] px-5 py-5 rounded-br-xl rounded-tr-xl rounded-bl-3xl flex flex-col'>
                  <TextDisplay>
                    <span style={{ fontSize:18}}>Direction to pay included, please send check to repair facility.</span>
                  </TextDisplay>
                </div>
              </div>

            </div>
            
            <div className='relative left-16 ml-1.5 mt-5'>
              <TextDisplay>
                <span style={{ fontSize:16}}>Sent July 18, 2023 - 8:30 AM</span>
              </TextDisplay>
            </div>

          </div> */}

          {/* <div className='w-full flex flex-col  justify-center mb-2'>

            <div className='w-full flex flex-row items-center gap-2'>
              <img src={EstimaticsImg.CHATUSERSICON} alt='icon'/>

              <div className='flex flex-col'>
                <div className='bg-[#B3E1FE] px-5 py-5 rounded-br-xl rounded-tr-xl rounded-bl-3xl flex flex-col'>
                  <TextDisplay>
                    <span style={{ fontSize:18}}>Possible total Loss</span>
                  </TextDisplay>
                </div>
              </div>

            </div>

            <div className='relative left-16 ml-1.5 mt-5'>
              <TextDisplay>
                <span style={{ fontSize:16}}>Sent July 18, 2023 - 8:31 AM</span>
              </TextDisplay>
            </div>

          </div> */}

        </Card>
    </div>
  )
}

export default MessageTab
